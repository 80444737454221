import axios from 'axios';
import partition from 'lodash/partition';
import { createNamespacedHelpers } from 'vuex';

import { INTERNZ, CUSTOM_DREAMVERSE_NAMES, HAS_INTERNZ_JOBS } from '@/constants/career/dreamverse';

import { isProduction, cdnBaseUrl } from '@/plugins/constants';
import dayjs, { getTimeInAsia } from '@/plugins/dayjs';

const NAMESPACE = 'dreamverse';
export { NAMESPACE as NAMESPACE_DREAMVERSE };
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfDreamverse,
	mapMutations as mapMutationsOfDreamverse,
	mapActions as mapActionsOfDreamverse,
};

export const FILTERED_DREAMVERSE_LIST = 'FILTERED_DREAMVERSE_LIST';
export const DREAMVERSE_ITEM = 'DREAMVERSE_ITEM';
export const DREAMVERSE_JOBS = 'DREAMVERSE_JOBS';
export const DREAMVERSE_VIDEOS = 'DREAMVERSE_VIDEOS';
export const DREAMVERSE_SITES = 'DREAMVERSE_SITES';
export const DREAMVERSE_NON_PARTICIPATING_TARGET = 'DREAMVERSE_NON_PARTICIPATING_TARGET';
export const DREAMVERSE_FOREIGN_ID = 'DREAMVERSE_FOREIGN_ID';
export const DREAMVERSE_KEY = 'DREAMVERSE_KEY';
export const DREAMVERSE_BUSINESS_YEAR = 'DREAMVERSE_BUSINESS_YEAR';
export const IS_FIRST_BUSINESS_YEAR = 'IS_FIRST_BUSINESS_YEAR';
export const IS_CUSTOM_DREAMVERSE = 'IS_CUSTOM_DREAMVERSE';
export const IS_INTERNZ = 'IS_INTERNZ';
export const INTERNZ_COMPANY = 'INTERNZ_COMPANY';
export const INTERNZ_JOBS = 'INTERNZ_JOBS';
export const IS_KOKKIRI_GONGJANG = 'IS_KOKKIRI_GONGJANG';
export const IS_LEGACY_VERSION = 'IS_LEGACY_VERSION';
export const IS_WITHIN_THREE_MONTHS_FROM_RECRUIT_END = 'IS_WITHIN_THREE_MONTHS_FROM_RECRUIT_END';

export const SET_DREAMVERSE_LIST = 'SET_DREAMVERSE_LIST';
export const SET_DREAMVERSE_ITEM = 'SET_DREAMVERSE_ITEM';
export const SET_DREAMVERSE_JOBS = 'SET_DREAMVERSE_JOBS';
export const SET_DREAMVERSE_VIDEOS = 'SET_DREAMVERSE_VIDEOS';
export const SET_DREAMVERSE_SITES = 'SET_DREAMVERSE_SITES';
export const SET_DREAMVERSE_FOREIGN_ID = 'SET_DREAMVERSE_FOREIGN_ID';
export const SET_DREAMVERSE_NON_PARTICIPATING_TARGET = 'SET_DREAMVERSE_NON_PARTICIPATING_TARGET';
export const SET_INTERNZ_COMPANY = 'SET_INTERNZ_COMPANY';
export const SET_INTERNZ_JOBS = 'SET_INTERNZ_JOBS';

export const LOAD_DREAMVERSE_LIST = 'LOAD_DREAMVERSE_LIST';
export const LOAD_DREAMVERSE_ITEM_FROM_NOTION = 'LOAD_DREAMVERSE_ITEM_FROM_NOTION';
export const LOAD_DREAMVERSE_ITEM_FROM_BASEROW = 'LOAD_DREAMVERSE_ITEM_FROM_BASEROW';
export const RESET_DREAMVERSE_DETAIL_INFO = 'RESET_DREAMVERSE_DETAIL_INFO';

export const state = () => ({
	dreamverseList: [],
	dreamverseItem: {},
	dreamverseJobs: [],
	dreamverseVideos: [],
	dreamverseSites: [],
	dreamverseNonParticipatingTarget: null,
	dreamverseForeignID: null,
	internzCompany: [],
	internzJobs: [],
});

export const getters = {
	[FILTERED_DREAMVERSE_LIST]: state => {
		const list = isProduction ? state.dreamverseList.filter(item => item.status) : state.dreamverseList;

		const [active, inactive] = partition(list, item =>
			getTimeInAsia().isSameOrBefore(dayjs(item.recruitEndDate).endOf('day')),
		);

		active.sort((a, b) => {
			const recruitEndDateComparison = dayjs(a.recruitEndDate).isSameOrBefore(dayjs(b.recruitEndDate)) ? -1 : 1;
			if (dayjs(a.recruitEndDate).isSame(dayjs(b.recruitEndDate))) {
				// 만약 날짜가 같으면 round를 내림차순으로 정렬
				return b.round - a.round;
			}
			return recruitEndDateComparison;
		});

		return [...active, ...inactive];
	},
	[DREAMVERSE_ITEM]: state => state.dreamverseItem,
	[DREAMVERSE_JOBS]: state => state.dreamverseJobs,
	[DREAMVERSE_VIDEOS]: state => state.dreamverseVideos,
	[DREAMVERSE_SITES]: state => state.dreamverseSites,
	[DREAMVERSE_NON_PARTICIPATING_TARGET]: state => state.dreamverseNonParticipatingTarget,
	[DREAMVERSE_FOREIGN_ID]: state => state.dreamverseForeignID,
	[DREAMVERSE_KEY]: state => state.dreamverseItem.key,
	[IS_CUSTOM_DREAMVERSE]: (state, getters) => {
		const key = state.dreamverseItem.key;
		if (getters[IS_INTERNZ]) {
			return true;
		}
		return CUSTOM_DREAMVERSE_NAMES.includes(key);
	},
	[DREAMVERSE_BUSINESS_YEAR]: state => state.dreamverseItem.business_year,
	[IS_FIRST_BUSINESS_YEAR]: state => state.dreamverseItem.business_year === 2023,
	[IS_INTERNZ]: state => state.dreamverseItem.partnerType === INTERNZ,
	[INTERNZ_COMPANY]: state => state.internzCompany,
	[INTERNZ_JOBS]: state => state.internzJobs,
	[IS_KOKKIRI_GONGJANG]: state => state.dreamverseItem.foreign_id === '713fec66-6dc0-4402-b06e-0ef18e4e76d6',
	[IS_LEGACY_VERSION]: state => state.dreamverseItem?.version === 1,
	[IS_WITHIN_THREE_MONTHS_FROM_RECRUIT_END]: state => {
		const { recruitEndDate } = state.dreamverseItem;
		function isWithinThreeMonths(date) {
			const threeMonthsFromDate = dayjs(date).add(3, 'month');
			return getTimeInAsia().isBefore(threeMonthsFromDate);
		}

		return isWithinThreeMonths(recruitEndDate);
	},
};

export const mutations = {
	[SET_DREAMVERSE_LIST]: (state, payload) => {
		state.dreamverseList = payload;
	},
	[SET_DREAMVERSE_ITEM]: (state, payload) => {
		if (!payload) {
			return null;
		}
		return (state.dreamverseItem = { ...state.dreamverseItem, ...payload });
	},
	[SET_DREAMVERSE_JOBS]: (state, payload) => {
		state.dreamverseJobs = payload;
	},
	[SET_DREAMVERSE_VIDEOS]: (state, payload) => {
		state.dreamverseVideos = payload;
	},
	[SET_DREAMVERSE_SITES]: (state, payload) => {
		state.dreamverseSites = payload;
	},
	[SET_DREAMVERSE_NON_PARTICIPATING_TARGET]: (state, payload) => {
		state.dreamverseNonParticipatingTarget = payload;
	},
	[SET_DREAMVERSE_FOREIGN_ID]: (state, payload) => {
		state.dreamverseForeignID = payload;
	},
	[SET_INTERNZ_COMPANY]: (state, payload) => {
		state.internzCompany = payload;
	},
	[SET_INTERNZ_JOBS]: (state, payload) => {
		state.internzJobs = payload;
	},
};

export const actions = {
	async [LOAD_DREAMVERSE_LIST]({ commit }) {
		try {
			const params = {
				filter__status__equal: Number(true),
				order_by: '-recruitEndDate',
			};
			const { data } = await this.$api.baserow.getDreamverseList(params);
			commit(SET_DREAMVERSE_LIST, data);
		} catch (error) {
			console.error(`${LOAD_DREAMVERSE_LIST} error: `, error);
		}
	},
	async loadInternzDetail(_, key) {
		try {
			const { data } = await axios.get('/cdn', {
				params: { resource: `${cdnBaseUrl}/data/dreamverse/internz_detail.json` },
			});
			return data.results
				.filter(item => item.properties.key?.rich_text[0].plain_text === key)
				.map(
					({
						properties: {
							dominant_color,
							faq,
							foreign_id,
							total_company,
							business_year,
							vp_text,
							vp_work_location,
							vp_experience_title,
							vp_experience_description,
							weeks,
							recruit_start_date,
							paper_screening_pass_date,
							interview_start_date,
							interview_end_date,
							final_pass_date,
							pre_education_start_date,
							pre_education_end_date,
							work_experience_date,
						},
					}) => {
						return {
							dominant_color: dominant_color.title[0].plain_text,
							faq: faq.url,
							foreign_id: foreign_id.relation[0].id,
							total_company: total_company.number ?? 0,
							business_year: parseInt(business_year.select?.name),
							vp_text: vp_text.rich_text[0]?.plain_text,
							vp_work_location: vp_work_location.rich_text[0]?.plain_text,
							vp_experience_title: vp_experience_title.rich_text[0]?.plain_text,
							vp_experience_description: vp_experience_description.rich_text[0]?.plain_text,
							weeks: weeks.number,
							recruit_start_date: recruit_start_date.rich_text[0]?.plain_text,
							paper_screening_pass_date: paper_screening_pass_date.rich_text[0]?.plain_text,
							interview_start_date: interview_start_date.rich_text[0]?.plain_text,
							interview_end_date: interview_end_date.rich_text[0]?.plain_text,
							final_pass_date: final_pass_date.rich_text[0]?.plain_text,
							pre_education_start_date: pre_education_start_date.rich_text[0]?.plain_text,
							pre_education_end_date: pre_education_end_date.rich_text[0]?.plain_text,
							work_experience_date: work_experience_date.rich_text[0]?.plain_text,
						};
					},
				);
		} catch (error) {
			console.error(error);
		}
	},
	async loadInternzCompany({ commit }, foreignID) {
		try {
			const { data } = await axios.get('/cdn', {
				params: { resource: `${cdnBaseUrl}/data/dreamverse/internz_company.json` },
			});
			const companyList = data.results
				.filter(item => item.properties.foreign_id.relation.some(({ id }) => id === foreignID))
				.map(({ id, properties: { company, description, location, logo_url, foreign_id } }) => {
					return {
						id,
						foreign_id: foreign_id.relation.map(({ id }) => id),
						company: company.title[0].plain_text,
						description: description.rich_text[0]?.plain_text,
						location: location.select?.name,
						logo_url: logo_url?.url,
					};
				});
			commit(SET_INTERNZ_COMPANY, companyList);
		} catch (error) {
			console.error('loadInternzCompany error: ', error);
		}
	},
	async loadInternzJobs({ commit }, { key, foreignID }) {
		if (!HAS_INTERNZ_JOBS.includes(key)) {
			return;
		}

		try {
			const { data } = await axios.get('/cdn', {
				params: { resource: `${cdnBaseUrl}/data/dreamverse/jobs/${key}.json` },
			});
			const jobs = data.results.map(
				({ properties: { foreign_company, job, description, person_limit, order, highlight } }) => {
					return {
						foreign_id: foreignID,
						foreign_company: foreign_company?.relation[0].id,
						job: job.title[0]?.plain_text,
						description: description.rich_text[0]?.plain_text,
						person_limit: person_limit?.number,
						order: order?.number,
						highlight: highlight.select?.name,
					};
				},
			);
			commit(SET_INTERNZ_JOBS, jobs);
		} catch (error) {
			console.error('loadInternzJobs error: ', error);
		}
	},
	async loadDreamverseDetail(_, key) {
		try {
			const { data } = await axios.get('/cdn', {
				params: { resource: `${cdnBaseUrl}/data/dreamverse/dreamverse_detail.json` },
			});
			const detail = data.results.filter(item => item.properties.key.rich_text[0].plain_text === key);
			return detail.map(
				({
					properties: {
						dominant_color,
						recruit_start_date,
						paper_screening_pass_date,
						final_pass_date,
						pre_education_start_date,
						pre_education_end_date,
						main_education_start_date,
						main_education_end_date,
						final_education_date,
						business_year,
						foreign_id,
					},
				}) => {
					return {
						dominant_color: dominant_color.title[0].plain_text,
						recruit_start_date: recruit_start_date.rich_text[0]?.plain_text,
						paper_screening_pass_date: paper_screening_pass_date.rich_text[0]?.plain_text,
						final_pass_date: final_pass_date.rich_text[0]?.plain_text,
						pre_education_start_date: pre_education_start_date.rich_text[0]?.plain_text,
						pre_education_end_date: pre_education_end_date.rich_text[0]?.plain_text,
						main_education_start_date: main_education_start_date.rich_text[0]?.plain_text,
						main_education_end_date: main_education_end_date.rich_text[0]?.plain_text,
						final_education_date: final_education_date.rich_text[0]?.plain_text,
						business_year: parseInt(business_year.select?.name),
						foreign_id: foreign_id.relation[0].id,
					};
				},
			);
		} catch (error) {
			console.error('loadDreamverseDetail error: ', error);
		}
	},
	async loadDreamverseJobs({ commit }, foreignID) {
		try {
			const { data } = await axios.get('/cdn', {
				params: { resource: `${cdnBaseUrl}/data/dreamverse/dreamverse_jobs.json` },
			});
			const jobs = data.results
				.filter(item => item.properties.foreign_id.relation[0]?.id === foreignID)
				.map(({ properties: { job, company, location, person_limit, description, highlight, foreign_id } }) => {
					return {
						job: job.title[0].plain_text,
						company: company.select?.name,
						location: location.select?.name,
						person_limit: person_limit.number,
						description: description.rich_text
							.reduce((prev, curr) => [...prev, curr.plain_text], [])
							.join(''),
						highlight: highlight.select?.name,
						foreign_id: foreign_id.relation.map(({ id }) => id),
					};
				});

			commit(SET_DREAMVERSE_JOBS, jobs);
		} catch (error) {
			console.error('loadDreamverseJobs error: ', error);
		}
	},
	async loadDreamverseVideos({ commit }, foreignID) {
		try {
			const { data } = await axios.get('/cdn', {
				params: { resource: `${cdnBaseUrl}/data/dreamverse/dreamverse_video.json` },
			});
			const dreamverseVideos = data.results
				.filter(item => item.properties.foreign_id?.relation.some(({ id }) => id === foreignID))
				.map(({ properties: { title, url, foreign_id } }) => {
					return {
						title: title.title[0].plain_text,
						url: url.url,
						foreign_id: foreign_id.relation.map(({ id }) => id),
					};
				});

			commit(SET_DREAMVERSE_VIDEOS, dreamverseVideos);
		} catch (error) {
			console.error('loadDreamverseVideos error: ', error);
		}
	},
	async [LOAD_DREAMVERSE_ITEM_FROM_NOTION]({ commit, dispatch, getters }, { key, partnerType }) {
		try {
			if (!key) {
				return;
			}

			if (partnerType === INTERNZ) {
				const item = await dispatch('loadInternzDetail', key);
				commit(SET_DREAMVERSE_ITEM, item[0]);
				const foreignID = item[0].foreign_id;
				await dispatch('loadInternzCompany', foreignID);
				await dispatch('loadInternzJobs', { key, foreignID });
			} else {
				const item = await dispatch('loadDreamverseDetail', key);
				commit(SET_DREAMVERSE_ITEM, item[0]);
				const foreignID = item[0].foreign_id;
				await dispatch('loadDreamverseVideos', foreignID);
				await dispatch('loadDreamverseSites', foreignID);
				await dispatch('loadDreamverseNonParticipatingTarget', foreignID);
				if (!getters[IS_CUSTOM_DREAMVERSE]) {
					await dispatch('loadDreamverseJobs', foreignID);
				}
			}
		} catch (error) {
			console.error(`${LOAD_DREAMVERSE_ITEM_FROM_NOTION} error: `, error);
		}
	},
	async [LOAD_DREAMVERSE_ITEM_FROM_BASEROW]({ commit }, { partnerWithRound }) {
		const params = {
			partnerWithRound,
		};
		const { data } = await this.$api.baserow.getDreamverseDetail(params);
		commit(SET_DREAMVERSE_ITEM, { sections: data });
	},
	async loadDreamverseSites({ commit }, foreignID) {
		try {
			const { data } = await axios.get('/cdn', {
				params: { resource: `${cdnBaseUrl}/data/dreamverse/dreamverse_site.json` },
			});
			const sites = await data.results.filter(item =>
				item.properties.foreign_id.relation.some(relationItem => relationItem.id === foreignID),
			);
			if (sites?.length === 0) {
				return;
			}

			const dreamverseSites = sites.map(({ properties: { home, youtube, blog } }) => {
				return {
					home: home?.url,
					youtube: youtube?.url,
					blog: blog?.url,
				};
			});

			commit(SET_DREAMVERSE_SITES, dreamverseSites[0]);
		} catch (error) {
			console.error('loadDreamverseSites error: ', error);
		}
	},
	async loadDreamverseNonParticipatingTarget({ commit }, foreignID) {
		try {
			const { data } = await axios.get('/cdn', {
				params: { resource: `${cdnBaseUrl}/data/dreamverse/dreamverse_non_participating_target.json` },
			});
			const targets = await data.results.filter(item =>
				item.properties.foreign_id.relation.some(relationItem => relationItem.id === foreignID),
			);
			if (targets?.length === 0) {
				return;
			}

			const result = targets.map(({ properties: { non_participating_target } }) => {
				return non_participating_target.rich_text
					.reduce((prev, curr) => [...prev, curr.plain_text], [])
					.join('');
			});
			commit(SET_DREAMVERSE_NON_PARTICIPATING_TARGET, result[0]);
		} catch (error) {
			console.error('loadDreamverseNonParticipatingTarget error: ', error);
		}
	},
	[RESET_DREAMVERSE_DETAIL_INFO]({ commit }) {
		commit(SET_DREAMVERSE_FOREIGN_ID, null);
		commit(SET_DREAMVERSE_NON_PARTICIPATING_TARGET, null);
		commit(SET_DREAMVERSE_ITEM, {});
		commit(SET_DREAMVERSE_JOBS, []);
		commit(SET_DREAMVERSE_VIDEOS, []);
		commit(SET_DREAMVERSE_SITES, []);
		commit(SET_INTERNZ_COMPANY, []);
		commit(SET_INTERNZ_JOBS, []);
	},
};
