export const DREAMVERSE_COMPANY = '드림버스컴퍼니';

export const DREAMVERSE_SITES_TITLE = {
	HOME: '홈페이지',
	YOUTUBE: '유튜브',
	BLOG: '블로그',
};

export const INTERNZ = 'internz';

export const CUSTOM_DREAMVERSE_NAMES = ['hansol-3', 'ybm-1', 'hansol-2', 'hansol-1', 'kiwon-1', 'kiwon-2', 'kiwon-3'];

export const CUSTOM_INTERNZ_YBM_ID = 'internz-ybm-14';

export const HAS_INTERNZ_JOBS = [
	'internz-3',
	'internz-4',
	'internz-5',
	'internz-6',
	'internz-7',
	'internz-8',
	'internz-9',
	'internz-10',
	'internz-11',
	'internz-12',
	'internz-13',
	'internz-14',
];

export const CUSTOM_DREAMVERSE_ITEMS = {
	'seohan-2': {
		title: '[2차] 서한그룹 드림버스컴퍼니',
		notice: `<ul>
			<li>사전직무교육 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일을 초과하여 수료기준에 미달하는 경우, 이후 일경험(가상형 인턴십)에는 참여 불가합니다.</li>
			<li>일경험(가상형 인턴십)은 일경험 전체 기간 중 80% 이상 출석해야 수료가 가능합니다. (100시간 중 80시간 이상)</li>
			<li>다른 참여자들의 성실한 참여와 일경험 업무 성과 창출에 심대하게 부정적인 영향을 끼친다고 판단되는 경우 참여 불가합니다.</li>
			<li>프로그램 중 중도 탈락 청년의 수당은 출석률에 비례한 지급 기준에 따라 지급됩니다.</li>
		</ul>`,
		nonParticipatingCondition: `<ul>
			[재직자]
			<li>프로그램 실시 신고 시점을 기준으로 고용보험전산망 피보험자 자격 기준으로 확인</li>
			<li>단, 주 30시간 미만 일자리에 근무하는 자는 미취업자로 간주하여 참여가능(근무시간이 명시된 근로계약서 제출 필요)</li>
			<br/>
			[사업자]
			<li>사업자등록증을 보유한 사업자(전년도 연매출 1억 5천만원 이상)</li>
			<li>단, 사업자등록을 한 경우에도, 휴업신고를 하는 등 실제 사업을 하지 아니하였음을 증명한 경우, 또는 소득금액증명원, 부가가치과세 표준증명원으로 전년도 사업소득이 1억 5천만원 미만임을 증명한 경우 참여 가능(소득증빙이 불가한 경우, 확인서를 받고 추후 증빙 제출)</li>
			<br/>
			[중복참여]
			<li>미래내일 일경험, 국민취업지원제도 일경험 프로그램을 포함하여 동일 연도에 최대 2회까지 일경험 프로그램(기업탐방형을 제외)에 참여할 수 있으나, 동시 또는 동일한 프로그램에는 재참여할 수 없으며, 자발적인 의사에 따라 중도 이탈한 경우에는 1회 참여한 것으로 봄</li>
			<li>질병·사고·재난 등 본인의 귀책사유 없이 발생한 중도이탈의 경우에는 참여횟수에 불포함(동일연도 기준: 2024.03.01 ~ 2025.02.28)</li>
			<br/>
			[타 사업과의 중복 참여]
			<li>타 정부부처, 지자체 등 지원 사업 참여자가 중복 참여하고자 할 경우 교육시간이 중복되지 않는 경우 참여 가능</li>
			<li>교육 시간의 경우 사업 운영 과정에서 지정된 시간내에 행해지는 집체 교육, 실시간 온라인 교육, 멘토링 등 모든 교육의 형태를 의미</li>
			<li>단, 중복 참여하는 경우 타 지원사업의 세부 실시 조건에 따라 교육 시간 중복 여부와 상관없이 참여 및 수당 지급이 제한 될 수 있음</li>
		</ul>`,
	},
	'humax-4': {
		title: '[4차] 휴맥스 드림버스컴퍼니',
		notice: `<ul>
			<li>사전직무교육 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일을 초과하여 수료기준에 미달하는 경우, 이후 일경험(가상형 인턴십)에는 참여 불가합니다.</li>
			<li>일경험(가상형 인턴십)은 일경험 전체 기간 중 80% 이상 출석해야 수료가 가능합니다. (100시간 중 80시간 이상)</li>
			<li>다른 참여자들의 성실한 참여와 일경험 업무 성과 창출에 심대하게 부정적인 영향을 끼친다고 판단되는 경우 참여 불가합니다.</li>
			<li>프로그램 중 중도 탈락 청년의 수당은 출석률에 비례한 지급 기준에 따라 지급됩니다.</li>
		</ul>`,
		nonParticipatingCondition: `<ul>
			[재직자]
			<li>프로그램 실시 신고 시점을 기준으로 고용보험전산망 피보험자 자격 기준으로 확인</li>
			<li>단, 주 30시간 미만 일자리에 근무하는 자는 미취업자로 간주하여 참여가능(근무시간이 명시된 근로계약서 제출 필요)</li>
			<br/>
			[사업자]
			<li>사업자등록증을 보유한 사업자(전년도 연매출 1억 5천만원 이상)</li>
			<li>단, 사업자등록을 한 경우에도, 휴업신고를 하는 등 실제 사업을 하지 아니하였음을 증명한 경우, 또는 소득금액증명원, 부가가치과세 표준증명원으로 전년도 사업소득이 1억 5천만원 미만임을 증명한 경우 참여 가능(소득증빙이 불가한 경우, 확인서를 받고 추후 증빙 제출)</li>
			<br/>
			[중복참여]
			<li>미래내일 일경험, 국민취업지원제도 일경험 프로그램을 포함하여 동일 연도에 최대 2회까지 일경험 프로그램(기업탐방형을 제외)에 참여할 수 있으나, 동시 또는 동일한 프로그램에는 재참여할 수 없으며, 자발적인 의사에 따라 중도 이탈한 경우에는 1회 참여한 것으로 봄</li>
			<li>질병·사고·재난 등 본인의 귀책사유 없이 발생한 중도이탈의 경우에는 참여횟수에 불포함(동일연도 기준: 2024.03.01 ~ 2025.02.28)</li>
			<br/>
			[타 사업과의 중복 참여]
			<li>타 정부부처, 지자체 등 지원 사업 참여자가 중복 참여하고자 할 경우 교육시간이 중복되지 않는 경우 참여 가능</li>
			<li>교육 시간의 경우 사업 운영 과정에서 지정된 시간내에 행해지는 집체 교육, 실시간 온라인 교육, 멘토링 등 모든 교육의 형태를 의미</li>
			<li>단, 중복 참여하는 경우 타 지원사업의 세부 실시 조건에 따라 교육 시간 중복 여부와 상관없이 참여 및 수당 지급이 제한 될 수 있음</li>
		</ul>`,
	},
	'kiwon-1': {
		title: '비수도권 지역인재 특화트랙<br />기원테크 드림버스컴퍼니',
		notice: `<ul>
			<li>사전직무교육 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일을 초과하여 수료기준에 미달하는 경우, 이후 일경험(가상형 인턴십)에는
			참여 불가합니다.</li>
			<li>가상형 인턴십 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일이 초과되는 경우, 다른 참여자들의 성실한 참여와 일경험 업무 성과
			창출에 심대하게 부정적인 영향을 끼친다고 판단되는 경우 참여 불가합니다.</li>
			<li>프로그램 중 중도 탈락 청년의 수당은 출석률에 비례한 지급 기준에 따라 지급됩니다.</li>
		</ul>`,
		nonParticipatingCondition: `<ul>
			[재직자]
			<li>프로그램 실시 신고 시점을 기준으로 고용보험전산망 피보험자 자격 기준으로 확인</li>
			<li>단, 주 30시간 미만 일자리에 근무하는 자는 미취업자로 간주하여 참여가능(근무시간이 명시된 근로계약서 제출 필요)</li>
			<br/>
			[사업자]
			<li>사업자등록증을 보유한 사업자(전년도 연매출 1억 5천만원 이상)</li>
			<li>단, 사업자등록을 한 경우에도, 휴업신고를 하는 등 실제 사업을 하지 아니하였음을 증명한 경우, 또는 소득금액증명원, 부가가치과세 표준증명원으로 전년도 사업소득이 1억 5천만원 미만임을 증명한 경우 참여 가능(소득증빙이 불가한 경우, 확인서를 받고 추후 증빙 제출)</li>
			<br/>
			[중복참여]
			<li>미래내일 일경험, 국민취업지원제도 일경험 프로그램을 포함하여 동일 연도에 최대 2회까지 일경험 프로그램(기업탐방형을 제외)에 참여할 수 있으나, 동시 또는 동일한 프로그램에는 재참여할 수 없으며, 자발적인 의사에 따라 중도 이탈한 경우에는 1회 참여한 것으로 봄</li>
			<li>질병·사고·재난 등 본인의 귀책사유 없이 발생한 중도이탈의 경우에는 참여횟수에 불포함(동일연도 기준: 2024.03.01 ~ 2025.02.28)</li>
			<br/>
			[타 사업과의 중복 참여]
			<li>타 정부부처, 지자체 등 지원 사업 참여자가 중복 참여하고자 할 경우 교육시간이 중복되지 않는 경우 참여 가능</li>
			<li>교육 시간의 경우 사업 운영 과정에서 지정된 시간내에 행해지는 집체 교육, 실시간 온라인 교육, 멘토링 등 모든 교육의 형태를 의미</li>
			<li>단, 중복 참여하는 경우 타 지원사업의 세부 실시 조건에 따라 교육 시간 중복 여부와 상관없이 참여 및 수당 지급이 제한 될 수 있음</li>
		</ul>`,
	},
	'kiwon-2': {
		title: '니트청년 특화트랙<br />기원테크 드림버스컴퍼니',
		vpBox: {
			title: '니트청년 드림버스컴퍼니는 <br />취업에 어려움을 겪는 청년들을 위한 <br />재택 참여형 인턴십 프로그램입니다.',
			items: [
				{
					title: '집에서 경험하는 일경험',
					description:
						'메타버스에 구축된 기원테크에서 공간의 제약 없이 기업이 제공하는 인턴십을 집에서 참여할 수 있습니다.',
				},
				{
					title: '공통교육과 심화교육',
					description:
						'자기 이해와 다양한 진로 탐색을 통해 성향에 맞는 일을 파악하고 적성에 맞는 직무를 선택할 수 있습니다.',
				},
				{
					title: '조직문화 적응과 실무경험',
					description:
						'기원테크의 조직 문화를 경험하고 현직자와 함께 현업 과제를 수행하며 실무 경험을 할 수 있습니다.',
				},
			],
		},
		notice: `<ul>
			<li>사전직무교육 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일을 초과하여 수료기준에 미달하는 경우, 이후 일경험(가상형 인턴십)에는
			참여 불가합니다.</li>
			<li>가상형 인턴십 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일이 초과되는 경우, 다른 참여자들의 성실한 참여와 일경험 업무 성과
			창출에 심대하게 부정적인 영향을 끼친다고 판단되는 경우 참여 불가합니다.</li>
			<li>프로그램 중 중도 탈락 청년의 수당은 출석률에 비례한 지급 기준에 따라 지급됩니다.</li>
		</ul>`,
		nonParticipatingCondition: `<ul>
		[재직자]
		<li>프로그램 실시 신고 시점을 기준으로 고용보험전산망 피보험자 자격 기준으로 확인</li>
		<li>단, 주 30시간 미만 일자리에 근무하는 자는 미취업자로 간주하여 참여가능(근무시간이 명시된 근로계약서 제출 필요)</li>
		<br/>
		[사업자]
		<li>사업자등록증을 보유한 사업자(전년도 연매출 1억 5천만원 이상)</li>
		<li>단, 사업자등록을 한 경우에도, 휴업신고를 하는 등 실제 사업을 하지 아니하였음을 증명한 경우, 또는 소득금액증명원, 부가가치과세 표준증명원으로 전년도 사업소득이 1억 5천만원 미만임을 증명한 경우 참여 가능(소득증빙이 불가한 경우, 확인서를 받고 추후 증빙 제출)</li>
		<br/>
		[중복참여]
		<li>미래내일 일경험, 국민취업지원제도 일경험 프로그램을 포함하여 동일 연도에 최대 2회까지 일경험 프로그램(기업탐방형을 제외)에 참여할 수 있으나, 동시 또는 동일한 프로그램에는 재참여할 수 없으며, 자발적인 의사에 따라 중도 이탈한 경우에는 1회 참여한 것으로 봄</li>
		<li>질병·사고·재난 등 본인의 귀책사유 없이 발생한 중도이탈의 경우에는 참여횟수에 불포함(동일연도 기준: 2024.03.01 ~ 2025.02.28)</li>
		<br/>
		[타 사업과의 중복 참여]
		<li>타 정부부처, 지자체 등 지원 사업 참여자가 중복 참여하고자 할 경우 교육시간이 중복되지 않는 경우 참여 가능</li>
		<li>교육 시간의 경우 사업 운영 과정에서 지정된 시간내에 행해지는 집체 교육, 실시간 온라인 교육, 멘토링 등 모든 교육의 형태를 의미</li>
		<li>단, 중복 참여하는 경우 타 지원사업의 세부 실시 조건에 따라 교육 시간 중복 여부와 상관없이 참여 및 수당 지급이 제한 될 수 있음</li>
	</ul>`,
	},
	'kiwon-3': {
		title: '비수도권 지역인재 특화트랙<br />기원테크 드림버스컴퍼니',
		notice: `<ul>
			<li>사전직무교육 일반 결근과 지각 누적 3회를 포함하여 전체 기간의 2일을 초과하여 수료기준에 미달하는 경우, 이후 일경험(가상형 인턴십)에는 참여 불가합니다.</li>
			<li>일경험(가상형 인턴십)은 일경험 전체 기간 중 80% 이상 출석해야 수료가 가능합니다. (100시간 중 80시간 이상)</li>
			<li>다른 참여자들의 성실한 참여와 일경험 업무 성과 창출에 심대하게 부정적인 영향을 끼친다고 판단되는 경우 참여 불가합니다.</li>
			<li>프로그램 중 중도 탈락 청년의 수당은 출석률에 비례한 지급 기준에 따라 지급됩니다.</li>
		</ul>`,
		nonParticipatingCondition: `<ul>
		[재직자]
		<li>프로그램 실시 신고 시점을 기준으로 고용보험전산망 피보험자 자격 기준으로 확인</li>
		<li>단, 주 30시간 미만 일자리에 근무하는 자는 미취업자로 간주하여 참여가능(근무시간이 명시된 근로계약서 제출 필요)</li>
		<br/>
		[사업자]
		<li>사업자등록증을 보유한 사업자(전년도 연매출 1억 5천만원 이상)</li>
		<li>단, 사업자등록을 한 경우에도, 휴업신고를 하는 등 실제 사업을 하지 아니하였음을 증명한 경우, 또는 소득금액증명원, 부가가치과세 표준증명원으로 전년도 사업소득이 1억 5천만원 미만임을 증명한 경우 참여 가능(소득증빙이 불가한 경우, 확인서를 받고 추후 증빙 제출)</li>
		<br/>
		[중복참여]
		<li>미래내일 일경험, 국민취업지원제도 일경험 프로그램을 포함하여 동일 연도에 최대 2회까지 일경험 프로그램(기업탐방형을 제외)에 참여할 수 있으나, 동시 또는 동일한 프로그램에는 재참여할 수 없으며, 자발적인 의사에 따라 중도 이탈한 경우에는 1회 참여한 것으로 봄</li>
		<li>질병·사고·재난 등 본인의 귀책사유 없이 발생한 중도이탈의 경우에는 참여횟수에 불포함(동일연도 기준: 2024.03.01 ~ 2025.02.28)</li>
		<br/>
		[타 사업과의 중복 참여]
		<li>타 정부부처, 지자체 등 지원 사업 참여자가 중복 참여하고자 할 경우 교육시간이 중복되지 않는 경우 참여 가능</li>
		<li>교육 시간의 경우 사업 운영 과정에서 지정된 시간내에 행해지는 집체 교육, 실시간 온라인 교육, 멘토링 등 모든 교육의 형태를 의미</li>
		<li>단, 중복 참여하는 경우 타 지원사업의 세부 실시 조건에 따라 교육 시간 중복 여부와 상관없이 참여 및 수당 지급이 제한 될 수 있음</li>
	</ul>`,
	},
};
