import axios from 'axios';

import { baseUrl, isLocal } from '@/plugins/constants';

const PREFIX = '/baserow';
const DREAMVERSE_PREFIX = `${PREFIX}/dreamverse`;
const nuxtBaseUrl = baseUrl + (isLocal ? ':8000' : '');
axios.defaults.baseURL = nuxtBaseUrl;

export default () => ({
	getSeSACList(params) {
		return axios.get(`${PREFIX}/class/training/list`, { params });
	},
	getSeSACDetail(params) {
		return axios.get(`${PREFIX}/class/training/detail`, { params });
	},
	getDreamverseList(params) {
		return axios.get(`${DREAMVERSE_PREFIX}/list`, { params });
	},
	getDreamverseDetail(params) {
		return axios.get(`${DREAMVERSE_PREFIX}/detail`, { params });
	},
	getDreamverseSectionYoutube(params) {
		return axios.get(`${DREAMVERSE_PREFIX}/detail/section-youtube`, { params });
	},
});
